import React, { useEffect, useState } from "react";
import SingleCelbsProflle from "../../Component/SingleProfile/SingleCelbsProflle";
import { getAllWithSlug } from "../../Api/Api";
import { useParams } from "react-router-dom";
import SEO from "../../SEO";
const SingleProfile = () => {
  const [data, setData] = useState();
  const { slug } = useParams();
  useEffect(() => {
    const loadData = async () => {
      try {
        const storedData = localStorage.getItem(slug);
        if (storedData) {
          setData(JSON.parse(storedData));
        } else {
          const response = await getAllWithSlug(slug);
          const { data } = response;
          setData(data);
          localStorage.setItem(slug, JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching movie data:", error);
      }
    };
    loadData();
  }, [slug]);
  console.log("data", data);
  return (
    <div>
      <SEO
        title={`Kavishala Entertainment | All Profile | ${data?.name}`}
        og_url={`/profile/${slug}`}
        og_type="article"
        og_title={`Kavishala Entertainment | All Profile | ${data?.name}`}
        og_description={data?.about}
        og_image={data?.image}
      />
      <SingleCelbsProflle data={data} />
    </div>
  );
};

export default SingleProfile;
