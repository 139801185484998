import React, { useEffect, useState } from "react";
import SingleCelbsProflle from "../../Component/SingleMovieAndLyrics/SingleCelbsProflle";
import { getsinglePageMoviesData } from "../../Api/Api";
import { useParams } from "react-router-dom";
import SEO from "../../SEO";
const SingleMovieProfile = () => {
  const [data, setData] = useState(() => {
    const storeMovies = localStorage.getItem("movies");
    return storeMovies ? JSON.parse(storeMovies) : null;
  });
  const { slug } = useParams();

  useEffect(() => {
    const loadData = async () => {
      try {
        const storedData = localStorage.getItem(slug);
        if (storedData) {
          setData(JSON.parse(storedData));
        } else {
          const response = await getsinglePageMoviesData(slug);
          const { data } = response;
          setData(data);
          // Store fetched data in local storage
          localStorage.setItem(slug, JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching movie data:", error);
      }
    };
    loadData();
  }, [slug]);
  console.log("data", data);
  return (
    <div>
      <SEO
        title={`Kavishala Entertainment | All Movies | ${data?.moviename}`}
        og_url={`/movies/${slug}`}
        og_type="article"
        og_title={`Kavishala Entertainment | All Movies | ${data?.moviename}`}
        og_description={data?.plot}
        og_image="https://entertainment.kavishala.com/images/post_og.png"
      />
      <SingleCelbsProflle data={data} />
    </div>
  );
};

export default SingleMovieProfile;
