import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-multi-carousel";
import Typography from "@mui/material/Typography";
import "react-multi-carousel/lib/styles.css";
import "../Celebrity/Celebrity.scss";
import { useNavigate, useLocation } from "react-router-dom";
import SkeletonCard from "../Skeleton/SkeletonCard";
import { getAllDirectors } from "../../Api/Api";
var temp = "/images/heroBG.png";
const AllprofileDirector = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const state1 = useLocation();
  console.log("state", state1);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const handleLoadmore = (pageSize) => {
    setPage(page + pageSize);

    getAllDirectors(page).then((res) => {
      setData(res?.data?.results);
    });
  };
  useEffect(() => {
    const loadData = () => {
      getAllDirectors(page).then((res) => {
        if (res?.status === 200) {
          setLoading(true);
          setData(res?.data?.results);
        }
      });
    };
    loadData();
  }, []);
  const handleNext = (value) => {
    navigate(`/profile/${value?.slug}`);
  };
  const Loader = () => {
    return Array(4)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };
  return (
    <>
      <div className="viewAll_container_head">
        <h1>All Directors</h1>
      </div>
      {loading === false ? (
        <div className="SkeletonCard">
          <Loader />
        </div>
      ) : (
        <div className="viewAll_container" style={{ padding: "0" }}>
          {data?.map((value) => {
            return (
              <Card
                sx={{ maxWidth: 200 }}
                style={{ margin: "2.5%", cursor: "pointer" }}
                className="celebs_carousels"
                onClick={() => {
                  handleNext(value);
                }}
              >
                <CardMedia
                  sx={{ width: 200, height: 250 }}
                  image={value?.image}
                  title="Celebrity Image"
                />
                <div className="celebsContainer">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="celebsdetails"
                  >
                    {value?.name}
                  </Typography>
                </div>
                <div className="celebsContainer">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="celebsdetails2"
                  >
                    Read More
                  </Typography>
                </div>
              </Card>
            );
          })}
        </div>
      )}
      {data.length > 24 ? (
        <div className="btnContainer">
          <button className="Loadmore" onClick={() => handleLoadmore(25)}>
            Load More
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AllprofileDirector;
