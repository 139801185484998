import React from "react";
import SEO from "../../SEO";
import AllMovielist from "../../Component/Celebrity/AllMovielist";
const MoviesProfile = () => {
  return (
    <div>
      <SEO
        title={`Kavishala Entertainment | All Movies `}
        og_url={"/viewAllMovies"}
        og_type="article"
        og_title={`Kavishala Entertainment | All Movies `}
        og_description={
          "Step into the star-studded world where glitz, glamour, and celebrity sparkle on every pixel."
        }
        og_image="https://entertainment.kavishala.com/images/post_og.png"
      />
      <AllMovielist />
    </div>
  );
};

export default MoviesProfile;
