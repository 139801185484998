import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-multi-carousel";
import Typography from "@mui/material/Typography";
import "react-multi-carousel/lib/styles.css";
import "../Celebrity/Celebrity.scss";
import { useNavigate, useLocation } from "react-router-dom";

import { getAllActor } from "../../Api/Api";

const AllData = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const state1 = useLocation();
  console.log("state", state);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(25);
  const handleLoadmore = (pageSize) => {
    setPage(page + pageSize);

    getAllActor(page).then((res) => {
      setData(res?.data?.results);
    });
  };

  const handleNext = (value) => {
    navigate(`/profile/${value?.slug}`);
  };
  const handleNext2 = (value) => {
    navigate(`/movies/${value?.slug}`);
  };
  const handleNext3 = (value) => {
    navigate(`/lyrics/${value?.slug}`);
  };
  return (
    <>
      <div className="viewAll_container">
        {state?.data?.map((value) => {
          return (
            <Card
              sx={{ maxWidth: 200 }}
              style={{ margin: "2.5%", cursor: "pointer" }}
              className="celebs_carousels"
              onClick={() => {
                handleNext(value);
              }}
            >
              <CardMedia
                sx={{ width: 200, height: 250 }}
                image={value?.image}
                title="Celebrity Image"
              />
              <div className="celebsContainer">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="celebsdetails"
                >
                  {value?.name}
                </Typography>
              </div>
              <div className="celebsContainer">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="celebsdetails2"
                >
                  Read More
                </Typography>
              </div>
            </Card>
          );
        })}
      </div>
      <div className="viewAll_container">
        {state?.movieData?.map((value) => {
          return (
            <Card
              sx={{ maxWidth: 200 }}
              style={{ margin: "2.5%", cursor: "pointer" }}
              className="celebs_carousels"
              onClick={() => {
                handleNext2(value);
              }}
            >
              <CardMedia
                sx={{ width: 200, height: 250 }}
                image={value?.image}
                title="Celebrity Image"
              />
              <div className="celebsContainer">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="celebsdetails"
                >
                  {value?.moviename}
                </Typography>
              </div>
              <div className="celebsContainer">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="celebsdetails2"
                >
                  Read More
                </Typography>
              </div>
            </Card>
          );
        })}
      </div>
      <div className="viewAll_container">
        {state?.lyricsData?.map((value) => {
          return (
            <Card
              sx={{ maxWidth: 200 }}
              style={{ margin: "2.5%", cursor: "pointer" }}
              className="celebs_carousels"
              onClick={() => {
                handleNext3(value);
              }}
            >
              <CardMedia
                sx={{ width: 200, height: 250 }}
                image={value?.image}
                title="Celebrity Image"
              />
              <div className="celebsContainer">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="celebsdetails"
                >
                  {value?.title}
                </Typography>
              </div>
              <div className="celebsContainer">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="celebsdetails2"
                >
                  Read More
                </Typography>
              </div>
            </Card>
          );
        })}
      </div>
      {/* {state.data.length > 24 ? (
        <div className="btnContainer">
          <button className="Loadmore" onClick={() => handleLoadmore(25)}>
            Load More
          </button>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default AllData;
