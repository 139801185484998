import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import "../AcademyHome/Home.scss";
import SearchIcon from "@mui/icons-material/Search";
import { SearchWords, getAllActor } from "../../Api/Api";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Carousel from "react-multi-carousel";

const Search = () => {
  const [data, setData] = useState([]);
  const [movieData, setMovieData] = useState([]);
  const [lyricsData, setLyricsData] = useState([]);
  const [word, setWord] = useState();
  const navigate = useNavigate();
  const [page, setPage] = useState(25);
  const search = () => {
    SearchWords(word, page)
      .then((res) => {
        console.log("dfghjh", res.data);
        setData(res?.data?.actors);
        setMovieData(res?.data?.movies);
        setLyricsData(res?.data?.lyrics);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleMore = (pageSize) => {
    setPage(page + pageSize);
    SearchWords(word, page)
      .then((res) => {
        setData(res?.data?.actors);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      SearchWords(word, page)
        .then((res) => {
          setData(res?.data?.actors);
          setMovieData(res?.data?.movies);
          setLyricsData(res?.data?.lyrics);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleNext = (value) => {
    navigate(`/profile/${value}`);
  };
  const handleNext2 = (value) => {
    navigate(`/movies/${value}`);
  };
  const handleNext3 = (value) => {
    navigate(`/lyrics/${value}`);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1220 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1220, min: 480 },
      items: 4.5,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1.5,
    },
  };
  const handleLoadmore = (pageSize) => {
    setPage(page + pageSize);

    getAllActor(page).then((res) => {
      setData(res?.data);
    });
  };
  return (
    <>
      <div
        className="Home_conatiner"
        style={{ minHeight: "600px", paddingTop: "5%" }}
      >
        <div>
          <h1 className="dataFind">
            Search Your Favourite Celebrity , Movies & Lyrics
          </h1>
        </div>
        <div className="input_container">
          <input
            name="question"
            id="search"
            placeholder="search ......"
            className="inputBox"
            onKeyDown={handleKeyDown}
            onChange={(e) => setWord(e.target.value)}
            required
          />
          <button className="search_btn" type="submit" onClick={search}>
            <SearchIcon />
          </button>
        </div>

        <div className="celebrityMain_container">
          {data?.length === 0 ? (
            ""
          ) : (
            <div className="view_all">
              <h6 className="head">Actors</h6>
              {data?.length <= 10 ? (
                ""
              ) : (
                <button
                  className="view_btn"
                  onClick={() =>
                    navigate(`/viewAll`, {
                      state: { data },
                    })
                  }
                >
                  VIEW MORE
                </button>
              )}
            </div>
          )}
          <Carousel
            responsive={responsive}
            arrows={true}
            swipeable={true}
            draggable={true}
            autoPlay={false}
            showDots={false}
            ssr={true}
            infinite
          >
            {data?.slice(0, 10)?.map((value) => {
              return (
                <Card
                  sx={{ maxWidth: 200 }}
                  style={{ margin: "2.5%", cursor: "pointer" }}
                  className="celebs_carousels"
                  onClick={() => {
                    handleNext(value?.slug);
                  }}
                >
                  <CardMedia
                    sx={{ maxWidth: 250, height: 250 }}
                    image={value?.image}
                    title="Celebrity Image"
                  />
                  <div className="celebsContainer">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="celebsdetails"
                    >
                      {value?.name}
                    </Typography>
                  </div>
                  <div className="celebsContainer">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="celebsdetails2"
                    >
                      Read More
                    </Typography>
                  </div>
                </Card>
              );
            })}
          </Carousel>
        </div>
        <div className="celebrityMain_container">
          {movieData?.length === 0 ? (
            ""
          ) : (
            <div className="view_all">
              <h6 className="head">Movies</h6>
              {movieData?.length <= 10 ? (
                ""
              ) : (
                <button
                  className="view_btn"
                  onClick={() =>
                    navigate(`/viewAll`, {
                      state: { movieData },
                    })
                  }
                >
                  VIEW MORE
                </button>
              )}
            </div>
          )}

          <Carousel
            responsive={responsive}
            arrows={true}
            swipeable={true}
            draggable={true}
            autoPlay={false}
            showDots={false}
            ssr={true}
            infinite
          >
            {movieData.slice(0, 10)?.map((value) => {
              return (
                <Card
                  sx={{ maxWidth: 200 }}
                  style={{ margin: "2.5%", cursor: "pointer" }}
                  className="celebs_carousels"
                  onClick={() => {
                    handleNext2(value?.slug);
                  }}
                >
                  <CardMedia
                    sx={{ maxWidth: 250, height: 250 }}
                    image={value?.image}
                    title="Celebrity Image"
                  />
                  <div className="celebsContainer">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="celebsdetails"
                    >
                      {value?.moviename}
                    </Typography>
                  </div>
                  <div className="celebsContainer">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="celebsdetails2"
                    >
                      Read More
                    </Typography>
                  </div>
                </Card>
              );
            })}
          </Carousel>
        </div>
        <div className="celebrityMain_container">
          {lyricsData?.length === 0 ? (
            ""
          ) : (
            <div className="view_all">
              <h6 className="head">Lyrics</h6>
              {lyricsData?.length <= 10 ? (
                ""
              ) : (
                <button
                  className="view_btn"
                  onClick={() =>
                    navigate(`/viewAll`, {
                      state: { lyricsData },
                    })
                  }
                >
                  VIEW MORE
                </button>
              )}
            </div>
          )}
          <Carousel
            responsive={responsive}
            arrows={true}
            swipeable={true}
            draggable={true}
            autoPlay={false}
            showDots={false}
            ssr={true}
            infinite
          >
            {lyricsData?.slice(0, 10)?.map((value) => {
              return (
                <Card
                  sx={{ maxWidth: 200 }}
                  style={{ margin: "2.5%", cursor: "pointer" }}
                  className="celebs_carousels"
                  onClick={() => {
                    handleNext3(value?.slug);
                  }}
                >
                  <CardMedia
                    sx={{ maxWidth: 250, height: 250 }}
                    image={value?.image}
                    title="Celebrity Image"
                  />
                  <div className="celebsContainer">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="celebsdetails"
                    >
                      {value?.title}
                    </Typography>
                  </div>
                  <div className="celebsContainer">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="celebsdetails2"
                    >
                      Read More
                    </Typography>
                  </div>
                </Card>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Search;
