import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-multi-carousel";
import Typography from "@mui/material/Typography";
import "react-multi-carousel/lib/styles.css";
import "../Celebrity/Celebrity.scss";
import { useNavigate, useLocation } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { getAllMovies, movieFilter } from "../../Api/Api";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
var temp = "/images/heroBG.png";
const AllMovielist = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const state1 = useLocation();
  console.log("state", state1);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(25);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleLoadmore = (pageSize) => {
    setPage(page + pageSize);

    getAllMovies(page).then((res) => {
      setData(res?.data?.results);
    });
  };
  useEffect(() => {
    const loadData = () => {
      getAllMovies(page).then((res) => {
        setData(res?.data?.results);
      });
    };
    loadData();
  }, []);
  const handleNext = (value) => {
    navigate(`/movies/${value?.slug}`);
  };

  const filterLanguage = (lang) => {
    movieFilter(lang).then((res) => {
      console.log("res", res);
      setData(res?.data?.results);
      setShow(false);
    });
  };
  return (
    <>
      <div className="viewAll_container_head">
        <h1>All Movies</h1>
        <FilterAltIcon className="filter" onClick={handleShow} />
      </div>
      {data?.length === 0 ? (
        <h1 className="dataFind"> No Data Found</h1>
      ) : (
        <div className="viewAll_container" style={{ padding: "0" }}>
          {data?.map((value) => {
            return (
              <Card
                sx={{ maxWidth: 200 }}
                style={{ margin: "2.5%", cursor: "pointer" }}
                className="celebs_carousels"
                onClick={() => {
                  handleNext(value);
                }}
              >
                <CardMedia
                  sx={{ width: 200, height: 250 }}
                  image={value?.image}
                  title="Celebrity Image"
                />
                <div className="celebsContainer">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="celebsdetails"
                  >
                    {value?.moviename}
                  </Typography>
                </div>
                <div className="celebsContainer">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="celebsdetails2"
                  >
                    Read More
                  </Typography>
                </div>
              </Card>
            );
          })}
        </div>
      )}
      {data.length > 24 ? (
        <div className="btnContainer">
          <button className="Loadmore" onClick={() => handleLoadmore(25)}>
            Load More
          </button>
        </div>
      ) : (
        ""
      )}
      <Drawer
        anchor={"right"}
        open={show}
        onClose={handleClose}
        className="Drawer"
      >
        <div
          className="DrawerMainContainer_movies"
          style={{ width: "300px !important" }}
        >
          <div
            className="TopConatiner"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2.5% 5%",
            }}
          >
            <div className="TopConatiner_Left">
              <h1 className="KavishalaText">Kavishala</h1>
            </div>
            <div className="TopConatiner_right">
              <CloseIcon
                className="close"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="mideum_Container">
            <div className="TopConatiner_Left">
              <h2 className="KavishalaText" style={{ padding: "0 5%" }}>
                Languages
              </h2>
            </div>
            <div className="left_Conatiner" style={{ padding: "5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Hindi")}
              >
                Hindi
              </h4>
            </div>
            <div className="left_Conatiner" style={{ padding: "5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Tamil")}
              >
                Tamil
              </h4>
            </div>
            <div className="left_Conatiner" style={{ padding: "5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Telugu")}
              >
                Telugu
              </h4>
            </div>
            <div className="left_Conatiner" style={{ padding: "5% 5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Malayalam")}
              >
                Malayalam
              </h4>
            </div>
            <div className="left_Conatiner" style={{ padding: "5% 5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Bhojpuri")}
              >
                Bhojpuri
              </h4>
            </div>
            <div className="left_Conatiner" style={{ padding: "5% 5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Punjabi")}
              >
                Punjabi
              </h4>
            </div>
            <div className="left_Conatiner" style={{ padding: "5% 5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Bengali")}
              >
                Bengali
              </h4>
            </div>
            <div className="left_Conatiner" style={{ padding: "5% 5%" }}>
              <h4
                className="contentHead"
                style={{ margin: "0", cursor: "pointer" }}
                onClick={() => filterLanguage("Haryanvi")}
              >
                Haryanvi
              </h4>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AllMovielist;
