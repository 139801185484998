// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkeletonCard {
  display: flex;
  justify-content: space-between;
}
.SkeletonCard .skeleton-card {
  width: 15%;
  height: 382px;
}
.SkeletonCard .skeleton-card .big-card {
  width: 100%;
}
.SkeletonCard .skeleton-card .big-card .MuiSkeleton-root {
  height: 200px;
  width: 100%;
  border-radius: 5px;
}
.SkeletonCard .skeleton-card .mini-card {
  margin-top: 20px;
  width: 100%;
}
.SkeletonCard .skeleton-card .mini-card .MuiSkeleton-root {
  height: 20px;
  width: 100%;
  border-radius: 0px;
}

@media (max-width: 600px) {
  .SkeletonCard {
    display: block;
    justify-content: space-between;
  }
  .SkeletonCard .skeleton-card {
    margin-top: 54px;
    width: 100%;
    height: 382px;
  }
  .SkeletonCard .skeleton-card .big-card {
    width: 100%;
  }
  .SkeletonCard .skeleton-card .big-card .MuiSkeleton-root {
    height: 200px;
    width: 100%;
    border-radius: 5px;
  }
  .SkeletonCard .skeleton-card .mini-card {
    margin-top: 20px;
    width: 100%;
  }
  .SkeletonCard .skeleton-card .mini-card .MuiSkeleton-root {
    height: 20px;
    width: 100%;
    border-radius: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/Skeleton/SkeletonCard.scss"],"names":[],"mappings":"AAAC;EACC,aAAA;EACA,8BAAA;AACF;AAAE;EAEA,UAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;AACJ;AAAI;EACE,aAAA;EACA,WAAA;EACA,kBAAA;AAEN;AACE;EACE,gBAAA;EACA,WAAA;AACJ;AAAI;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AAEN;;AAGA;EACE;IACE,cAAA;IACA,8BAAA;EAAF;EACA;IACE,gBAAA;IACA,WAAA;IACA,aAAA;EACF;EACE;IACE,WAAA;EACJ;EAAI;IACE,aAAA;IACA,WAAA;IACA,kBAAA;EAEN;EACE;IACE,gBAAA;IACA,WAAA;EACJ;EAAI;IACE,YAAA;IACA,WAAA;IACA,kBAAA;EAEN;AACF","sourcesContent":[" .SkeletonCard{\n  display: flex;\n  justify-content: space-between;\n  .skeleton-card {\n  // margin-top: 54px;\n  width: 15%;\n  height: 382px;\n\n  .big-card {\n    width: 100%;\n    .MuiSkeleton-root {\n      height: 200px;\n      width: 100%;\n      border-radius: 5px;\n    }\n  }\n  .mini-card {\n    margin-top: 20px;\n    width: 100%;\n    .MuiSkeleton-root {\n      height: 20px;\n      width: 100%;\n      border-radius: 0px;\n    }\n  }\n}\n }\n@media (max-width: 600px) {\n  .SkeletonCard{\n    display: block;\n    justify-content: space-between;\n  .skeleton-card {\n    margin-top: 54px;\n    width: 100%;\n    height: 382px;\n\n    .big-card {\n      width: 100%;\n      .MuiSkeleton-root {\n        height: 200px;\n        width: 100%;\n        border-radius: 5px;\n      }\n    }\n    .mini-card {\n      margin-top: 20px;\n      width: 100%;\n      .MuiSkeleton-root {\n        height: 20px;\n        width: 100%;\n        border-radius: 0px;\n      }\n    }\n  }\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
