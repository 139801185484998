import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import AppsIcon from "@mui/icons-material/Apps";
import Drawer from "@mui/material/Drawer";
import { div } from "react-router-dom";
import "../Navbar/navbar.scss";
import logo from "../../images/logo.jpg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import GooglePlay from "../../images/download1.png";
import { useNavigate } from "react-router-dom";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    console.log("hello");
    window.scrollTo(0, 0);
  }, []);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar className="navbar">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
              className="logo_conatiner"
            >
              <img
                src={logo}
                alt="Kavishala Logo "
                className="logo"
                onClick={() => navigate("/")}
              />
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge color="error">
                  <SearchIcon onClick={() => navigate("/search")} />
                </Badge>
              </IconButton>

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleShow}
                color="inherit"
              >
                <AppsIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <Drawer
        anchor={"right"}
        open={show}
        onClose={handleClose}
        className="Drawer"
      >
        <div className="DrawerMainContainer">
          <div className="TopConatiner">
            <div className="TopConatiner_Left">
              <h1 className="KavishalaText">Kavishala</h1>
            </div>
            <div className="TopConatiner_right">
              <CloseIcon className="close" onClick={handleClose} />
            </div>
          </div>
          <div className="mideum_Container">
            <div className="left_Conatiner">
              <h1 className="contentHead">POETRY & STORIES </h1>
              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/post")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Browse (All Posts)
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/sootradhar")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Sootradhar
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open("https://kavishala.in/@kavishalaopinion")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Opinion
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open("https://kavishala.in/@kavishala-labs")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Labs
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open("https://kavishala.in/@kavishala-daily")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Daily
                </a>
              </div>

              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/chapters")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Chapters
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/authors")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  All Authors
                </a>
              </div>
              <div
                className="dropdown-item"
                href="/https://kavishala.in/kavishalaQuiz"
                onClick={() =>
                  window.open("https://kavishala.in/kavishalaQuiz")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Quiz
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open("https://kavishala.in/@kavishala-interviews")
                }
              >
                <b>
                  <a className="dropdown-item family" onClick={handleClose}>
                    Kavishala Interviews
                  </a>
                </b>
              </div>

              <div
                className="dropdown-item"
                href="/https://kavishala.in/childrens-literature"
                onClick={() =>
                  window.open("https://kavishala.in/childrens-literature")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Childrens Literature
                </a>
              </div>
              <div
                className="dropdown-item"
                href="/https://kavishala.in/childrens-literature"
                onClick={() =>
                  window.open("https://entertainment.kavishala.com/")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Entertainment
                </a>
              </div>
              <h1 className="contentHead">EVENTS</h1>
              <div
                className="dropdown-item"
                href="/https://kavishala.in/events"
                onClick={() => window.open("https://kavishala.in/events")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  All Events
                </a>
              </div>

              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/talks")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Talks
                </a>
              </div>
              <div
                className="dropdown-item"
                href="/https://kavishala.in/she-the-leader"
                onClick={() =>
                  window.open("https://kavishala.in/she-the-leader")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  She the Leader
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open("https://kavishala.in/talking-books")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Talking Books
                </a>
              </div>
              <div
                className="dropdown-item"
                href="https://aaghaaz.kavishala.in/"
                onClick={() => window.open("https://aaghaaz.kavishala.in/")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Aaghaaz
                </a>
              </div>
              <div
                className="dropdown-item"
                href="https://conclave.kavishala.in/"
                onClick={() => window.open("https://conclave.kavishala.in/")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Conclave
                </a>
              </div>
              <div
                className="dropdown-item"
                href="/https://kavishala.in/kavishala-samvad"
                onClick={() =>
                  window.open("https://kavishala.in/kavishala-samvad")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Samvad
                </a>
              </div>
            </div>
            <div className="right_container">
              <h1 className="contentHead">VIDEOS</h1>
              <div
                href="https://www.youtube.com/@Kavishala"
                onClick={() =>
                  window.open("https://www.youtube.com/@Kavishala")
                }
                className="dropdown-item family"
              >
                <a
                  className="dropdown-item family"
                  onClick={handleClose}
                  style={{ marginBottom: "20px", padding: "5px 0" }}
                >
                  Kavishala TV
                </a>
              </div>
              <div
                onClick={() => window.open("http://bigjourneys.in")}
                className="dropdown-item family"
              >
                <a
                  className="dropdown-item family"
                  onClick={handleClose}
                  style={{ marginBottom: "20px", padding: "5px 0" }}
                >
                  Big Journeys
                </a>
              </div>
              <h1 className="contentHead">LEARN</h1>
              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/suno")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Suno
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open("https://kavishala.in/kavishalaQuiz")
                }
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Quiz
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/workshops")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  All Workshops
                </a>
              </div>
              <div
                className="dropdown-item"
                onClick={() => window.open("https://kavishala.in/KavishalaAI")}
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala AI
                </a>
              </div>
              <div onClick={() => window.open("https://academy.kavishala.com")}>
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Academy
                </a>
              </div>
              <h1 className="contentHead">MORE</h1>
              <div
                onClick={() => window.open("http://books.kavishala.in/")}
                className="dropdown-item family"
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Books
                </a>
              </div>
              <div
                onClick={() => window.open("https://store.kavishala.com/")}
                className="dropdown-item family"
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Store
                </a>
              </div>
              <div
                onClick={() => window.open("https://ambassadors.kavishala.in/")}
                className="dropdown-item family"
              >
                <a className="dropdown-item family" onClick={handleClose}>
                  Kavishala Campus Ambassador
                </a>
              </div>
              <h1 className="contentHead">STAY CONNECTED</h1>
              <div className="drawer_social_icon_container">
                <YouTubeIcon
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.youtube.com/@Kavishala")
                  }
                />
                <TwitterIcon
                  className="social_icon"
                  onClick={() => window.open("https://twitter.com/kavishala")}
                />
                <FacebookIcon
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.facebook.com/kavishala.in")
                  }
                />
                <InstagramIcon
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.instagram.com/kavishala.in/")
                  }
                />
              </div>
              <div>
                <h6 className="downlod_kavishala">Download Kavishala App :</h6>
                <img
                  src={GooglePlay}
                  style={{ width: "40%", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.kavishala.foreantech"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Navbar;
