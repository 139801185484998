import React, { useEffect } from "react";
import "../SingleProfile/SingleProfile.scss";
import temp from "../../images/image.jpg";
import { getsinglePageLyricsData } from "../../Api/Api";
import { useParams } from "react-router-dom";
import { Padding } from "@mui/icons-material";
const SingleLyrics = ({ data }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="single_profile_container">
        <div className="">
          <h2 className="actoreName">{data?.name}</h2>
        </div>
        <div className="single_main_container">
          <div className="single_leftSide">
            <img src={data?.image} alt="" className="single_profile_img" />
          </div>
          <div className="single_RightSide">
            <h2 className="about">About</h2>
            <p
              className="aboutDetails"
              style={{
                width: "100%",
                overflow: "hidden",
                display: "-webkit-box",
                webkitBoxOrient: "vertical",
                webkitLineClamp: "4",
              }}
            >
              {data?.raw_lyrics}
            </p>
          </div>
        </div>
      </div>
      <div
        className="Single_profile_details_conatainer"
        style={{ padding: "0 5%" }}
      >
        <div className="Single_profileDetails">
          <h1 className="head">Lyrics Line</h1>
          <p dangerouslySetInnerHTML={{ __html: data?.lyrics }}></p>
        </div>
      </div>
      <div
        className="Single_profile_details_conatainer"
        style={{ padding: "0 5%" }}
      >
        <div className="Single_profileDetails">
          <h1 className="head">Song Details</h1>
        </div>
        <div className="Single_profile_details_name">
          <ul className="Single_profile_details_list">
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Song Name</h6>
              <h6 className="heading_details">{data?.title}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Lyricist Name</h6>
              <h6 className="heading_details">{data?.lyricist}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Singers Name</h6>
              <h6 className="heading_details">{data?.singers}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Languages</h6>
              <h6 className="heading_details">{data?.language}</h6>
            </li>

            <li className="Single_profile_details_list_row">
              <h6 className="heading">Duration</h6>
              <h6 className="heading_details">{data?.duration}</h6>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SingleLyrics;
