import React, { useEffect } from "react";
import "../SingleProfile/SingleProfile.scss";
import temp from "../../images/image.jpg";
import { getsinglePageMoviesData } from "../../Api/Api";

import { Padding } from "@mui/icons-material";
const SingleCelbsProflle = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="single_profile_container">
        <div className="">
          <h2 className="actoreName">{data?.name}</h2>
        </div>
        <div className="single_main_container">
          <div className="single_leftSide">
            <img src={data?.image} alt="" className="single_profile_img" />
          </div>
          <div className="single_RightSide">
            <h2 className="about">About</h2>
            <p className="aboutDetails">{data?.plot}</p>
          </div>
        </div>
      </div>
      <div
        className="Single_profile_details_conatainer"
        style={{ padding: "0 5%" }}
      >
        <div className="Single_profileDetails">
          <h1 className="head">Storyline</h1>
          <p>{data?.plot}</p>
        </div>
      </div>
      <div
        className="Single_profile_details_conatainer"
        style={{ padding: "0 5%" }}
      >
        <div className="Single_profileDetails">
          <h1 className="head">Movie Details</h1>
        </div>
        <div className="Single_profile_details_name">
          <ul className="Single_profile_details_list">
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Movie Name</h6>
              <h6 className="heading_details">{data?.moviename}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Movie Category</h6>
              <h6 className="heading_details">{data?.movie_category}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Languages</h6>
              <h6 className="heading_details">{data?.languages}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Released Year</h6>
              <h6 className="heading_details">{data?.released_year}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Duration</h6>
              <h6 className="heading_details">{data?.duration}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Writers</h6>
              <h6 className="heading_details">{data?.writers}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Casting</h6>
              <h6 className="heading_details">{data?.casting}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Director</h6>
              <h6 className="heading_details">{data?.director}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Production</h6>
              <h6 className="heading_details">{data?.production}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Ratings</h6>
              <h6 className="heading_details">{data?.Imdb_ratings}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Rated</h6>
              <h6 className="heading_details">{data?.rated}</h6>
            </li>
            {data?.grossWorldWide ? (
              <li className="Single_profile_details_list_row">
                <h6 className="heading">GrossWorldWide</h6>
                <h6 className="heading_details">{data?.grossWorldWide}</h6>
              </li>
            ) : (
              ""
            )}
            {data?.cause_of_death ? (
              <li className="Single_profile_details_list_row">
                <h6 className="heading">Cause of death</h6>
                <h6 className="heading_details">{data?.cause_of_death}</h6>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SingleCelbsProflle;
