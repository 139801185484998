import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Layout/Layout.scss";
import { getCarousel } from "../../Api/Api";
import temp from "../../images/image.jpg";
export default function Layout1({ data }) {
  const history = useNavigate();
  const [carouselData, setCarouselData] = useState([]);
  useEffect(() => {
    const loadData = () => {
      getCarousel().then((res) => {
        console.log("response", res?.data);
        setCarouselData(res?.data);
      });
    };
    loadData();
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      arrows={true}
      swipeable={false}
      draggable={true}
      autoPlay={false}
      showDots={false}
      ssr={true}
      renderDotsOutside={true}
      infinite
      containerClass="carousel-container"
    >
      {carouselData?.map((value) => (
        <div
          // className="layout1"
          style={
            {
              // backgroundImage: `url(${temp})`,
            }
          }
          onClick={() => window.open(value?.url_text)}
        >
          <img src={value?.image} alt="" className="layout1" />
        </div>
      ))}
    </Carousel>
  );
}
