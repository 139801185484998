import React from "react";
import Layout1 from "../../Component/Layout/Layout1";
import Celebrity from "../../Component/Celebrity/Celebrity";
import Actress from "../../Component/Celebrity/Actress";
import MusicDepartment from "../../Component/Celebrity/MusicDepartment";
import Producer from "../../Component/Celebrity/Producer";
import Director from "../../Component/Celebrity/Director";
import Wrters from "../../Component/Celebrity/Wrters";
import Movies from "../../Component/Celebrity/Movies";
import Lyrics from "../../Component/Celebrity/Lyrics";
const Home = () => {
  return (
    <div>
      <Layout1 />
      <Celebrity />
      <Actress />
      <MusicDepartment />
      <Producer />
      <Director />
      <Wrters />
      <Movies />
      <Lyrics />
    </div>
  );
};

export default Home;
