import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-multi-carousel";
import Typography from "@mui/material/Typography";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { getAllMusics } from "../../Api/Api";
import "../Celebrity/Celebrity.scss";
import SkeletonCard from "../Skeleton/SkeletonCard";
var temp = "/images/heroBG.png";
const MusicDepartment = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1220 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1220, min: 480 },
      items: 4.5,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1.5,
    },
  };
  const handleNext = (slug) => {
    navigate(`/profile/${slug}`);
  };
  useEffect(() => {
    const loadData = () => {
      getAllMusics(page).then((res) => {
        if (res?.status === 200) {
          setLoading(true);
          setData(res?.data?.results);
        }
      });
    };
    loadData();
  }, []);
  const Loader = () => {
    return Array(6)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };
  return (
    <div className="celebrityMain_container">
      <div>{/* <Search /> */}</div>
      <div className="view_all">
        <h6 className="head">Music Departement</h6>
        <button
          className="view_btn"
          onClick={() => navigate(`/viewAllMusicDepartment`)}
        >
          VIEW MORE
        </button>
      </div>
      {loading === false ? (
        <div className="SkeletonCard">
          <Loader />
        </div>
      ) : (
        <Carousel
          responsive={responsive}
          arrows={true}
          swipeable={true}
          draggable={true}
          autoPlay={false}
          showDots={false}
          ssr={true}
          infinite
        >
          {data?.slice(0, 10).map((value) => {
            return (
              <Card
                sx={{ maxWidth: 200 }}
                style={{ margin: "2.5%", cursor: "pointer" }}
                className="celebs_carousels"
                onClick={() => {
                  handleNext(value?.slug);
                }}
              >
                <CardMedia
                  sx={{ maxWidth: 250, height: 250 }}
                  image={value?.image}
                  title="Celebrity Image"
                />
                <div className="celebsContainer">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="celebsdetails"
                  >
                    {value?.name}
                  </Typography>
                </div>
                <div className="celebsContainer">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="celebsdetails2"
                  >
                    Read More
                  </Typography>
                </div>
              </Card>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default MusicDepartment;
