import React, { useEffect, useState } from "react";
import SingleCelbsProflle from "../../Component/SingleMovieAndLyrics/SingleCelbsProflle";
import SingleLyrics from "../../Component/SingleMovieAndLyrics/SingleLyrics";
import { getsinglePageLyricsData } from "../../Api/Api";
import { useParams } from "react-router-dom";
import SEO from "../../SEO";
const SingleLyricsProfile = () => {
  const [data, setData] = useState();
  const { slug } = useParams();
  useEffect(() => {
    const loadData = async () => {
      try {
        const storedData = localStorage.getItem(slug);
        if (storedData) {
          setData(JSON.parse(storedData));
        } else {
          const response = await getsinglePageLyricsData(slug);
          const { data } = response;
          setData(data);
          localStorage.setItem(slug, JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching movie data:", error);
      }
    };
    loadData();
  }, [slug]);
  console.log("data", data);
  return (
    <div>
      <SEO
        title={`Kavishala Entertainment | All Movies | ${data?.lyricist}`}
        og_url={`/lyrics/${slug}`}
        og_type="article"
        og_title={`Kavishala Entertainment | All Movies | ${data?.lyricist}`}
        og_description={data?.raw_lyrics}
        og_image="https://entertainment.kavishala.com/images/post_og.png"
      />
      <SingleLyrics data={data} />
    </div>
  );
};

export default SingleLyricsProfile;
