import axios from "axios";
const baseUrl = "https://admin.kavishala.in/";

const SearchWords = (data, page) => {
  const urlPath = `entertainment/find/${data}/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getAllActor = (page) => {
  const urlPath = `actors/actor/all/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getCarousel = () => {
  const urlPath = `actors/see/carousel/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllWithSlug = (slug) => {
  const urlPath = `actors/${slug}/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllActoress = (page) => {
  const urlPath = `actors/actress/all/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllProducers = (page) => {
  const urlPath = `actors/producers/all/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllDirectors = (page) => {
  const urlPath = `actors/directors/all/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllMusics = (page) => {
  const urlPath = `actors/musicdep/all/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllWriters = (page) => {
  const urlPath = `actors/writers/all/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllMovies = (page) => {
  const urlPath = `movies/allmovies/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getsinglePageMoviesData = (slug) => {
  const urlPath = `movies/${slug}/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getsinglePageLyricsData = (slug) => {
  const urlPath = `lyrics/${slug}/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllLyrics = (page) => {
  const urlPath = `lyrics/all/?page_size=${page}`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const movieFilter = (lang) => {
  const urlPath = `movies/lang/${lang}/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const musicFilter = (lang) => {
  const urlPath = `lyrics/lang/${lang}/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  getAllActor,
  getAllActoress,
  getAllWithSlug,
  SearchWords,
  getCarousel,
  getAllProducers,
  getAllWriters,
  getAllDirectors,
  getAllMusics,
  getAllMovies,
  getsinglePageMoviesData,
  getsinglePageLyricsData,
  getAllLyrics,
  movieFilter,
  musicFilter,
};
