import React from "react";
import AllprofileActress from "../../Component/Celebrity/AllprofileActress";
import SEO from "../../SEO";
const ActressProfile = () => {
  return (
    <div>
      <SEO
        title={`Kavishala Entertainment | All Actress `}
        og_url={"/viewAllActress"}
        og_type="article"
        og_title={`Kavishala Entertainment |All Actress `}
        og_description={
          "Step into the star-studded world where glitz, glamour, and celebrity sparkle on every pixel."
        }
        og_image="https://entertainment.kavishala.com/images/post_og.png"
      />
      <AllprofileActress />
    </div>
  );
};

export default ActressProfile;
