import React from "react";
import Allprofilemusic from "../../Component/Celebrity/Allprofilemusic";
import SEO from "../../SEO";
const MusicProfile = () => {
  return (
    <div>
      <SEO
        title={`Kavishala Entertainment | All Music Department`}
        og_url={"/viewAllMusicDepartment"}
        og_type="article"
        og_title={`Kavishala Entertainment | All Music Department`}
        og_description={
          "Step into the star-studded world where glitz, glamour, and celebrity sparkle on every pixel."
        }
        og_image="https://entertainment.kavishala.com/images/post_og.png"
      />
      <Allprofilemusic />
    </div>
  );
};

export default MusicProfile;
