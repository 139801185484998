import React, { useEffect, useState } from "react";
import "../SingleProfile/SingleProfile.scss";

const SingleCelbsProflle = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="single_profile_container">
        <div className="">
          <h2 className="actoreName">{data?.name}</h2>
        </div>
        <div className="single_main_container">
          <div className="single_leftSide">
            <img src={data?.image} alt="" className="single_profile_img" />
          </div>
          <div className="single_RightSide">
            <h2 className="about">About</h2>
            <p className="aboutDetails">{data?.about}</p>
          </div>
        </div>
      </div>
      <div className="Single_profile_details_conatainer">
        <div className="Single_profileDetails">
          <h1 className="head">Personal Details</h1>
        </div>
        <div className="Single_profile_details_name">
          <ul className="Single_profile_details_list">
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Name</h6>
              <h6 className="heading_details">{data?.name}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Alternative names</h6>
              <h6 className="heading_details">{data?.alternative_name}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Height</h6>
              <h6 className="heading_details">{data?.height}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Born</h6>
              <h6 className="heading_details">{data?.dob}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Place Of Birth</h6>
              <h6 className="heading_details">{data?.place_of_birth}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Spouse</h6>
              <h6 className="heading_details">{data?.spouse}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Role</h6>
              <h6 className="heading_details">{data?.role}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Parents</h6>
              <h6 className="heading_details">{data?.parents}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Famous For</h6>
              <h6 className="heading_details">{data?.famous_for}</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">All Movies</h6>
              <h6 className="heading_details">{data?.movies} & many more</h6>
            </li>
            <li className="Single_profile_details_list_row">
              <h6 className="heading">Other works</h6>
              <h6 className="heading_details">{data?.other_works}</h6>
            </li>
            {data?.dod ? (
              <li className="Single_profile_details_list_row">
                <h6 className="heading">Death of date</h6>
                <h6 className="heading_details">{data?.dod}</h6>
              </li>
            ) : (
              ""
            )}
            {data?.cause_of_death ? (
              <li className="Single_profile_details_list_row">
                <h6 className="heading">Cause of death</h6>
                <h6 className="heading_details">{data?.cause_of_death}</h6>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SingleCelbsProflle;
