import React from "react";
// import HomePage from "./Pages/AcademyHome/HomePage";
import Home from "./Pages/HomePage/Home";
// import AllData from "./Pages/AllPage/AllData";
import Footer from "./Component/Footer/Footer";
import Profile from "./Pages/AllProfile/Profile";
import Search from "./Component/Search/Search";
import SingleProfile from "./Pages/SingleProfile/SingleProfile";
import ActressProfile from "./Pages/AllProfileActress/ActressProfile";
import DirectorProfiles from "./Pages/AllProfileDirector/DirectorProfiles";
import ProducerProfile from "./Pages/AllProfileProducer/ProducerProfile";
import MusicProfile from "./Pages/AllProfileMusicDepartment/MusicProfile";
import WritersProfile from "./Pages/AllProfileWriters/WritersProfile";
import MoviesProfile from "./Pages/AllMovies/MoviesProfile";
import LyricsProfile from "./Pages/AllLyrics/LyricsProfile";
import SingleMovieProfile from "./Pages/SingleMovieLyrics/SingleMovieProfile";
import SingleLyricsProfile from "./Pages/SingleMovieLyrics copy/SingleLyricsProfile";
import AllData from "./Component/SearchAlldata/AllData";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import NavBar from "./Component/Navbar/Navbar";
const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<Navigate to="/words" />} /> */}
        <Route path="/search" element={<Search />} />
        <Route path="/profile/:slug" element={<SingleProfile />} />
        <Route path="/viewAllActor" element={<Profile />} />
        <Route path="/viewAllActress" element={<ActressProfile />} />
        <Route path="/viewAllDirectors" element={<DirectorProfiles />} />
        <Route path="/viewAllProducers" element={<ProducerProfile />} />
        <Route path="/viewAllMusicDepartment" element={<MusicProfile />} />
        <Route path="/viewAllWriters" element={<WritersProfile />} />
        <Route path="/viewAllMovies" element={<MoviesProfile />} />
        <Route path="/viewLyrics" element={<LyricsProfile />} />
        <Route path="/movies/:slug" element={<SingleMovieProfile />} />
        <Route path="/lyrics/:slug" element={<SingleLyricsProfile />} />
        <Route path="/viewAll" element={<AllData />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
